$primaryBg: #161616;
$sideWidth: 180px;
$stepWidth: 640px;

.sign-up--wrap {
	display: flex;
	position: relative;
	background: $primaryBg;
	min-height: 100vh;
	&:before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 5;
		@include gradient(
			to bottom,
			$primaryBg,
			darken($primaryBg, 2)
		);
	}
	&:before {
		$width: $sideWidth + $stepWidth;
		content: '';
		display: block;
		display: none;
		width: $width;
		height: 40px;
		background: rgba(169, 141, 245, 0.205);
		position: absolute;
		z-index: 200;
		top: 0;
		left: calc(50% - ((#{$width})/2));
	}
}

.sign-up--side {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: flex-end;
	position: relative;
}

.sign-up--step {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
}

.sign-up--side-inner {
	width: $sideWidth;
	z-index: 30;
	position: relative;
}

.sign-up--step-inner {
	width: $stepWidth;
	border: 1px solid lighten($primaryBg, 14);
	z-index: 30;
	position: relative;
	background: lighten($primaryBg, 4);
	border-top: 0;
	border-bottom: 0;
}

.sign-up--side-container {
	width: $sideWidth;
	height: 100%;
	display: flex;
	padding: 28px;
	padding-left: 0;
	position: fixed;
	flex-direction: column;
	justify-content: space-between;
}

.sign-up--step-container {
	padding: 38px;
	padding-bottom: 38px + 77px;
	position: relative;
	h2 {
		color: darken($textWhite, 6);
		margin: 0 auto 28px;
		max-width: 50%;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
		line-height: 1.4;
		&.less-pad {
			margin: 0 auto 10px;
		}
	}
	h2 + p {
		color: $textGrey;
		font-size: 14px;
		text-align: center;
		font-weight: 600;
		margin: 0 0 32px;
	}
	.app--photo-button {
		margin: 0 0 38px;
	}
	.app--r-select [class$=-menu] {
		z-index: 87;
	}
	.app--r-select:not(:last-child),
	.app-input:not(:last-child) {
		margin: 0 0 22px;
	}
	.app-button {
		width: 100%;
	}
	.app-legend:not(:last-child) {
		margin: 0 0 32px;
	}
	.app-legend-head h4 {
		background: lighten($primaryBg, 4);
		@include gradient(
			to bottom,
			lighten($primaryBg, 4) 50%,
			lighten($backgroundPrimary, 3) 50%
		);
	}
	.app--grid {
		margin: 0 0 48px;
		&:nth-last-child(2) {
			margin-bottom: 85px;
		}
	}
	& > .search {
		margin: 0 0 28px;
	}
	.proceed-btn,
	.app--pagination {
		width: $stepWidth - 2px;
		padding: 18px 22px;
		border-top: 1px solid rgba(lighten($primaryBg, 12), .7);
		background: rgba(lighten($primaryBg, 8), .7);
		backdrop-filter: blur(10px);
		position: fixed;
		z-index: 86;
		bottom: 0;
		right: 0;
		left: calc(50% - ((#{$stepWidth - 2px} - #{$sideWidth}) / 2));
	}
	.fields {
		margin: 0 0 38px;
	}
	.btns button:not(:last-child) {
		margin: 0 0 18px;
	}
	.app--map-wrap {
		width: $stepWidth - 2px;
		z-index: 90;
		position: fixed;
		left: calc(50% - ((#{$stepWidth - 2px} - #{$sideWidth}) / 2));
		right: 0;
		bottom: 0;
		.map--search {
			top: 56px;
		}
	}
	p.message {
		color: $textGrey;
		margin: 18px 0 0;
		font-size: 15px;
		text-align: center;
		font-weight: 500;
	}
}

.video--legend .app-legend-body {
	padding: 18px;
	.app--video-wrap {
		margin: 0 0 28px;
	}
	p.error {
		color: $danger;
		margin: 12px 0 0;
		font-size: 14px;
		text-align: center;
		font-weight: 600;
	}
	p.video__note {
		color: darken($textWhite, 10);
		margin: 0 0 12px;
		font-size: 14px;
		text-align: center;
		font-weight: 500;
		line-height: 1.4;
	}
}

.sign-up--title {
	width: $stepWidth - 2;
	position: sticky;
	display: flex;
	align-items: stretch;
	z-index: 100;
	top: -0.5px;
	backdrop-filter: blur(10px);
	background: rgba(lighten($primaryBg, 8), .7);
	border-bottom: 1px solid rgba(lighten($primaryBg, 12), .7);
	.app-square-icon {
		border: 0;
		cursor: default;
		padding: 0 18px 0 22px;
		position: relative;
		font-size: 0;
		min-width: auto;
		min-height: auto;
		background: transparent;
		box-shadow: none;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -2;
			opacity: 0;
			pointer-events: none;
			transition: opacity .2s ease;
			@include gradient(
				to right,
				rgba(lighten($backgroundPrimary, 6), .9),
				rgba(lighten($backgroundPrimary, 6), 0)
			);
		}
		svg {
			fill: rgba(255, 255, 255, .1);
			transition: fill .2s ease, transform .2s ease;
			@include backface(16px);
		}
		&:not(:disabled) {
			cursor: pointer;
			svg {
				fill: #fff;
			}
			&:hover {
				background: transparent !important;
				&:before {
					opacity: 1;
				}
				svg {
					fill: #fff !important;
					transform: translateX(-2px);
				}
			}
		}
	}
	h1 {
		color: darken($textWhite, 10);
		padding: 16px 22px;
		font-size: 18px;
		font-weight: 700;
	}
	.app-square-icon + h1 {
		padding: 16px 22px 16px 0;
	}
}

@media screen and (max-width: 870px) {
	.sign-up--step {
		width: 100%;
	}
	.sign-up--step-inner {
		width: calc(100% - 24px * 2);
		margin: 0 auto;
	}
	.sign-up--side {
		display: none;
	}
	.sign-up--step-container h2 {
		max-width: 80%;
	}
	.sign-up--step-container .app--map-wrap,
	.sign-up--step-container .proceed-btn,
	.sign-up--step-container .app--pagination,
	.sign-up--title {
		left: 25px;
		right: 25px;
		width: auto;
	}

}

@media screen and (max-width: 540px) {
	.sign-up--step-inner {
		width: 100%;
		margin: 0 auto;
		border: 0;
	}
	.sign-up--step-container {
		padding: 22px;
	}
	.sign-up--step-container .app--map-wrap,
	.sign-up--step-container .proceed-btn,
	.sign-up--step-container .app--pagination,
	.sign-up--title {
		left: 0;
		right: 0;
	}
}
