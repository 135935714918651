.manage-aside {
	width: 230px;
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	right: auto;
	bottom: 0;
	padding: 28px 20px;
	transform: translate3d(0, 0, 0);
	background: darken($backgroundSecondary, 7.6);
	border-right: 1px solid darken($backgroundSecondary, 1);
	nav {
		margin: 16px 0 0;
		flex-grow: 1;
		a {
			font: 600 15.5px 'Quicksand', Sans-serif;
			color: $textGrey;
			display: block;
			padding: 8px 12px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			user-select: none;
			border-radius: 3px;
			&:not(:last-child) {
				margin: 0 0 10px;
			}
			&.active {
				color: $primary;
				background: darken($backgroundSecondary, 4.6);
				svg {
					fill: $primary;
				}
			}
			svg {
				fill: $textGrey;
				width: 14px;
				margin: 0 8px 0 0;
			}
		}
	}
	.logout button {
		width: 100%;
	}
}

.manage-content {
	padding: 38px;
	position: fixed;
	min-width: 800px;
	top: 0;
	left: 230px;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	&.with-side {
		right: 413px;
		min-width: 680px;
	}
}

.page-empty {
	font-size: 16px;
	font-weight: 500;
}

.page-aside {
	width: 414px;
	position: fixed;
	top: 0;
	left: auto;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	border-left: 1px solid lighten($backgroundPrimary, 9);
	background-color: lighten($backgroundPrimary, 4);
}

.page-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 20px;
	h1 {
		display: flex;
		font-size: 28px;
		align-items: center;
		.loader {
			margin: 3px 0 0 9px;
		}
		span {
			margin: 3px 0 0 9px;
			color: $primary;
			font-size: 20px;
			font-weight: 600;
		}
		.app-square-icon {
			width: 35px;
			height: 35px;
			margin: 0 14px 0 0;
			min-width: auto;
			min-height: auto;
		}
	}
	.amount {
		color: $primary;
		padding: 7.5px 9.5px;
		font-size: 16px;
		font-weight: 600;
		background: rgba($primary, .1);
	}
}

.payouts--page .page-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 38px;
	padding-top: 22px;
	.pages {
		margin: 0;
	}
}

.page-middle {
	margin: 38px 0 20px;
	display: flex;
	align-items: center;
	.app-select {
		margin: 0 20px 0 0;
		&:last-child {
			margin: 0;
		}
	}
}

.page-controls {
	display: flex;
	.app-button {
		margin: 0 20px 0 0;
		&:last-child {
			margin: 0;
		}
	}
}

.users-list {

}

.users-list--item {
	padding: 12px;
	margin: 0 0 20px;
	border: 1px solid lighten($backgroundPrimary, 5);
	display: flex;
	align-items: center;
	flex-direction: row;
	background: lighten($backgroundPrimary, 2);
	transition: background-color .2s ease, border-color .2s ease;
	border-radius: 3px;
	&:hover {
		background: lighten($backgroundPrimary, 4);
		border-color: lighten($backgroundPrimary, 8);
	}
	&:last-child {
		margin: 0;
	}
	.user-content {
		margin: 0 12px;
		overflow: hidden;
		flex-grow: 1;
		flex-shrink: 1;
		.push-name {
			color: $primary;
			// color: $textGrey;
			margin: 0 0 8px;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
	h4 {
		color: #fff;
		font: 700 15px 'Quicksand', Sans-serif;
		margin: 0 0 6px;
	}
	h4 + p {
		color: $textGrey;
		font: 600 14px 'Quicksand', Sans-serif;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.user-actions {
		white-space: nowrap;
		button:not(:first-child) {
			margin: 0 0 0 12px;
		}
	}
	&.clickable {
		cursor: pointer;
	}
}

.user-role {
	color: $textGrey;
	padding: 2px 4px;
	display: inline-block;
	vertical-align: top;
	font-size: 10px;
	font-weight: 700;
	background: lighten($backgroundSecondary, 10);
	border-radius: 3px;
	&:not(:first-child) {
		margin-left: 6px;
	}
	&.admin {
		color: #2f7dff;
		background: rgba(#2f7dff, .1);
	}
	&.business {
		color: lighten($secondary, 20);
		background: rgba(lighten($secondary, 20), .1);
	}
	&.athlete {
		color: $primary;
		background: rgba($primary, .1);
	}
	&.big {
		font-size: 14px;
		backdrop-filter: blur(10px);
	}
}

.request-status {
	color: $textGrey;
	padding: 2px 4px;
	display: inline-block;
	vertical-align: middle;
	font-size: 10px;
	font-weight: 700;
	background: lighten($backgroundSecondary, 2);
	&.request-s5 {
		color: $primary;
		background: rgba($primary, .1);
	}
}

.user-avatar {
	width: 50px;
	position: relative;
	img {
		width: 50px;
		height: 50px;
		border: 1px solid rgba(#fff, .15);
		display: block;
		object-fit: cover;
		border-radius: 3px;
	}
	.counter {
		background: $danger;
		position: absolute;
		top: -8px;
		right: -8px;
		padding: 0px 4px;
		font-size: 12px;
		font-weight: 700;
		border: 4px solid lighten($backgroundPrimary, 2);
		border-radius: 16px;
		min-width: 28px;
		text-align: center;
	}
}

.avatar-empty {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $backgroundSecondary;
	border-radius: 3px;
}

.app-over-item .app-over-item__inner.create {
	padding: 24px 28px;
	.actions button {
		width: 100%;
		margin: 20px 0 0;
		display: block;
	}
	.over-item-title {
		margin: 0 0 20px;
		h4 {
			color: #fff;
			font-weight: 600;
		}
		button {
			border: 1px solid rgba(#fff, .15);
		}
		svg {
			fill: #fff;
		}
	}
	.app-input,
	.app--r-select {
		margin: 0 0 20px;
	}
}
.app-over-item .app-over-item__inner.create-promo-code {
	width: 700px;
}

.app-over-item .app-over-item__inner.request {
	width: 340px;
	padding: 24px 28px;
	.app-legend h4 {
		background: #282828;
	}
	.actions button {
		width: 100%;
		margin: 20px 0 0;
		display: block;
	}
	.over-item-title {
		margin: 0 0 20px;
		h4 {
			color: #fff;
			font-weight: 600;
		}
		button {
			border: 1px solid rgba(#fff, .15);
		}
		svg {
			fill: #fff;
		}
	}
	.app-input {
		margin: 0 0 20px;
	}
	.app-option-view {
		padding: 12px 14px;
	}
}

.user-profile {
	.user-overlay {
		@include absolute-fill(100);
		@include gradient(
			to bottom,
			rgba(lighten($backgroundPrimary, 4), 0) 10%,
			rgba(lighten($backgroundPrimary, 4), .9) 70%,
			lighten($backgroundPrimary, 4)
		);
		&.empty {
			@include gradient(
				to bottom,
				rgba(darken($backgroundPrimary, 40), .3),
				lighten($backgroundPrimary, 4)
			);
		}
	}
	.user-icon {
		position: absolute;
		z-index: 1000;
		top: 50%;
		left: 50%;
		padding: 20px;
		transform: translate(-50%, -90%);
		background: rgba($primary, .05);
		border-radius: 50%;
		svg {
			fill: $primary;
			width: 42px;
			height: 42px;
			display: block;
		}
	}
	.user-background {
		height: 410px;
		background: no-repeat top center/cover;
		position: relative;
	}
	.user-info {
		z-index: 110;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 28px;
		text-align: center;
		h4 {
			margin: 6px 0 0;
			font-size: 20px;
			font-weight: 700;
		}
		h6 {
			color: $textGrey;
			margin: 4px 0 0;
			font-size: 14px;
			font-weight: 600;
		}
	}
}

.page-aside .user-content {
	padding: 0 20px 20px;
	position: relative;
	z-index: 200;
	.app-legend h4 {
		background: lighten($backgroundPrimary, 4);
	}
	.app-legend:not(:last-child) {
		margin: 0 0 30px;
	}
	.user-actions {
		.app-button {
			width: 100%;
			&:not(:last-child) {
				margin: 0 0 16px;
			}
		}
	}
}

.request-list--item {
	padding: 12px;
	margin: 0 0 20px;
	border: 1px solid lighten($backgroundPrimary, 5);
	display: flex;
	align-items: center;
	flex-direction: row;
	background: lighten($backgroundPrimary, 2);
	cursor: pointer;
	transition: background .2s ease;
	border-radius: 3px;
	&:hover {
		background: lighten($backgroundPrimary, 4);
		border-color: lighten($backgroundPrimary, 8);
	}
	&:last-child {
		margin: 0;
	}
	svg {
		fill: $primary;
		width: 20px;
	}
	h4 {
		color: #fff;
		font: 700 15px 'Quicksand', Sans-serif;
		margin: 0 0 6px;
	}
	h4 + p {
		color: $textGrey;
		font: 600 14px 'Quicksand', Sans-serif;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		a {
			color: $primary;
			text-decoration: underline;
			font-weight: 700;
		}
		b {
			color: #fff;
			font-weight: 700;
		}
	}
	h4 + p + p {
		color: $primary;
		font: 800 12px 'Quicksand', Sans-serif;
		margin: 6px 0 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.request-item--content {
	margin: 0 14px;
	flex-grow: 1;
	flex-shrink: 1;
}

.request-item--amount {
	color: $primary;
	padding: 3px 5px;
	font-size: 14px;
	font-weight: 600;
	background: rgba($primary, .1);
	&:not(:last-child) {
		margin-right: 12px;
	}
}

.app-option-view.service {
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.app-option-view--label {
		color: $textGrey;
		font-size: 10px;
		font-weight: 700;
		text-transform: uppercase;
	}
	.app-option-view--value {
		display: flex;
		align-items: center;
		p {
			font-size: 20px;
			font-weight: 600;
		}
		p:not(:first-child):last-child {
			color: $textGrey;
			margin: 0 0 0 4px;
			font-size: 12px;
			font-weight: 600;
		}
	}
}

.stats-page {
	display: flex;
	flex-wrap: wrap;
	& > div {
		width: calc(50% - 20px);
		margin: 0 40px 40px 0;
		border: 1px solid lighten($backgroundSecondary, 4);
		padding: 20px;
		background: darken($backgroundSecondary, 4);
		border-radius: 3px;
		.app-legend-head h4 {
			@include gradient(
				to bottom,
				darken($backgroundSecondary, 4) 50%,
				lighten($backgroundPrimary, 3) 50%
			);
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
		&:last-child {
			margin: 0;
		}
	}
}

.payouts--header {
	display: block;
	a {
		color: $primary;
		margin: 6px 0 0;
		display: inline-block;
		font-size: 16px;
		font-weight: 600;
		text-decoration: underline;
	}
}

.page-header-flex-wrap {
	display: flex;
	justify-content: space-between;
}

.payouts--page {
	padding: 0;
	.page-header {
		padding: 38px;
		display: block;
		padding-bottom: 0;
		a {
			color: $primary;
			margin: 6px 0 0;
			display: inline-block;
			font-size: 16px;
			font-weight: 600;
			text-decoration: underline;
		}
	}
	.app--check {
		width: 22px;
		margin: 0;
		display: block;
	}
	table {
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
		tr {
			border-top: 1px solid #252525;
		}
		tr:last-child {
			border-bottom: 1px solid #252525;
		}
		tr:nth-child(odd) {
			background: #1f1f1f;
		}
		thead td {
			color: $textGrey;
			font-size: 10px;
			font-weight: 700;
			text-transform: uppercase;
		}
		tfoot td:last-child {
			padding-top: 12px;
			padding-bottom: 12px;
			padding-left: 4px;
		}
		td {
			color: darken(#fff, 6);
			padding: 12px;
			padding-left: 4px;
			padding-right: 4px;
			font-size: 13px;
			font-weight: 500;
			vertical-align: middle;
			&.empty {
				color: darken($textGrey, 12);
			}
			&:last-child {
				padding: 0;
				padding-right: 0 !important;
			}
		}
		td:first-child {
			padding-left: 38px;
			padding-right: 0;
		}
		td:last-child {
			padding-right: 38px;
		}
	}
	.amount {
		input {
			width: 90px;
			color: darken(#fff, 6);
			font-size: 13px;
			font-weight: 500;
			padding: 12px;
			padding-left: 4px;
			padding-right: 38px;
		}
	}
	.amount-blank {
		width: 90px;
	}
}

.eased {
	opacity: 0;
	transition: opacity .2s ease;
	&.shown {
		opacity: 1;
	}
}

.profile-edit {
	padding: 0;
	.edit-head--inner {
		padding: 38px;
		position: relative;
	}
	.edit-head--bg-over,
	.edit-head--bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		background: no-repeat center center/cover;
		border-radius: 9px 9px 0 0;
	}
	.edit-head--bg-over {
		z-index: 20;
		border-radius: 8px 8px 0 0;
		backdrop-filter: blur(12px);
		@include gradient(to bottom, rgba(#181818, .7), rgba(#1b1b1b, 1));
	}
	.edit-head--title {
		display: flex;
		min-height: 56px;
		align-items: center;
		position: relative;
		z-index: 30;
		h1 {
			font-size: 28px;
			min-height: 36px;
			line-height: 36px;
			font-weight: 700;
		}
		p {
			color: $primary;
			font-size: 16px;
			font-weight: 600;
		}
		.app-square-icon {
			width: 35px;
			height: 35px;
			min-width: auto;
			min-height: auto;
			position: absolute;
			top: -28px;
			right: -28px;
			border: none;
			border-radius: 8px;
			svg { fill: $textGrey; }
		}
		.user-avatar {
			width: 56px;
			height: 56px;
			margin: 0 20px 0 0;
		}
	}
	.app--tabs-head {
		border-top: 1px solid #323232;
	}
	.edit--modal-wrap {
		padding: 38px;
	}
	.app-legend:not(:first-child) {
		margin: 28px 0 0;
	}
	.app-legend--item .app-button {
		width: 100%;
	}
	.app-legend + .app-button {
		width: 100%;
		margin: 28px 0 0;
		&:not(:last-child) {
			margin-bottom: 18px;
		}
	}
	p.message {
		color: $textGrey;
		font-size: 15px;
		text-align: center;
		font-weight: 600;
		transition: color .2s ease;
		&.active {
			color: $danger;
		}
	}
	.field--wrapper {
		padding: 28px;
		& > div:not(:last-child) {
			margin: 0 0 28px;
		}
	}
}

.edit--modal {
	width: $editModalWidth;
	padding: 0 !important;
	background: #1d1d1d !important;
	border-radius: 12px;
	.app--map-wrap {
		position: fixed;
		width: $editModalWidth;
		left: calc(50% - (#{$editModalWidth}/2));
	}
}

.manage-overlay {
	position: fixed;
	transform: translate3d(0, 0, 0);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background: rgba(#000, .7);
	backdrop-filter: blur(10px);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: opacity .2s ease;
	.content {
		display: flex;
		padding: 40px;
		background: rgba(#000, .3);
		align-items: center;
		border-radius: 12px;
		flex-direction: column;
		h2 {
			color: darken(#fff, 6);
			margin: 18px 0 0;
			font-size: 22px;
			font-weight: 800;
		}
		h6 {
			color: $textGrey;
			margin: 6px 0 0;
			font-size: 14px;
			font-weight: 800;
		}
		.loader i {
			width: 32px;
			height: 32px;
			border-width: 2px;
		}
	}
}

.manage-content.manage_overlay {
	overflow: hidden;
	.manage-overlay {
		opacity: 1;
		pointer-events: auto;
	}
}

.promo-tags {
	margin: 10px 0 0;
	display: flex;
	flex-direction: row;
}

.promo-tag {
	padding: 2px 6px;
	border-radius: 6px;
	font-size: 13px;
	font-weight: 700;
	color: lighten($grey, 46);
	background: rgba(lighten($grey, 46), .05);
	&:not(:last-child) {
		margin-right: 6px;
	}
	&.used {
		color: $primary;
		background: rgba($primary, .15);
	}
}

.archive-switch {
	display: flex;
	align-items: center;
	p {
		color: darken($white, 40);
		margin: 0 0 0 10px;
		font-size: 15px;
		font-weight: 600;
		line-height: 20px;
	}
}

.modal--row {
	display: flex;
	flex-direction: row;
	& > div {
		width: 50%;
		flex-grow: 1;
		flex-shrink: 1;
		&:nth-child(2n) {
			margin-left: 28px !important;
		}
	}
}

.manage-aside--tenants {
}

.manage-content--tenants .manage-content {
	.page-header {
		margin-bottom: 0;
	}
}

.manage-content--tenants {
	table {
		width: 100%;
    border-spacing: 0 20px;
		border-collapse: separate;
		tr {
			margin-bottom: 20px;
		}
		.logo-wrap {
			display: inline-block;
			padding: 4px 12px;
			border-radius: 5px;
			vertical-align: top;
			background-color: lighten($backgroundSecondary, 4);
			img {
				width: 140px;
			}
		}
		tbody tr {
			cursor: pointer;
			transition: background-color .2s ease;
			border-radius: 5px;
			background-color: $backgroundSecondary;
			&:hover {
				background-color: lighten($backgroundSecondary, 2);
			}
		}
		thead td {
			color: $textGrey;
			padding: 0;
			font-size: 14px;
			font-weight: 400;
			background-color: transparent;
		}
		td {
			color: darken(#fff, 6);
			padding: 10px;
			font-size: 18px;
			text-align: center;
			font-weight: 600;
			vertical-align: middle;
			&.empty {
				color: darken($textGrey, 12);
			}
			&:last-child {
				padding: 0;
				padding-right: 0 !important;
			}
		}
		td:first-child {
			border-radius: 5px 0 0 5px;
		}
		td:last-child {
			border-radius: 0 5px 5px 0;
		}
		.logo,
		.name {
			width: auto;
			text-align: left;
		}
		.logo {
			width: 20px + 140px + 12px * 2;
		}
		.name {
		}
	}
}
