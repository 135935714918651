.app-square-icon {
	border: 1px solid rgba(#fff, .15);
	min-width: 50px;
	min-height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: border-color .2s ease, background-color .2s ease;
	background: lighten($backgroundPrimary, 5);
	box-shadow: 0 4px 6px rgba(darken($backgroundPrimary, 1), .2);
	border-radius: 3px;
	svg {
		fill: $primary;
		transition: fill .2s ease;
	}
	&.clickable {
		cursor: pointer;
		&:not(:disabled):hover {
			background: lighten($backgroundPrimary, 8);
			border-color: rgba(#fff, .15);
			svg {
				fill: $primary;
			}
		}
	}
}
