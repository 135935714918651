$primary: #cc6202;
$secondary: #036379;
$white: #fff;
$grey: #383838;
$darkGrey: #4a4a4a;
$danger: #e02e2e;
$danger2: #d3465a;
$backgroundPrimary: #181818;
$backgroundSecondary: lighten($backgroundPrimary, 6.4); // #282828;
$backgroundTertiary: lighten($backgroundPrimary, 8.5); // #2e2e2e;

$textGrey: #9b9b9b;
$textWhite: #fff;

$asideWidth: 300px;
$mainWidth: 720px;

$editModalWidth: 760px;
